import type { WebMerchantAppComponentsDto, WebStoreComponentsDto } from '@goparrot/webstore-sdk';
import type { PropsWithChildren } from 'react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

export const ComponentsConfigContext = createContext<WebStoreComponentsDto & WebMerchantAppComponentsDto>(
  {} as WebStoreComponentsDto & WebMerchantAppComponentsDto,
);

interface Props {
  initial: WebStoreComponentsDto & WebMerchantAppComponentsDto;
}

export const ComponentsConfigProvider: React.FC<PropsWithChildren<Props>> = ({ initial, children }) => {
  const [state, setState] = useState<WebStoreComponentsDto & WebMerchantAppComponentsDto>(initial);

  useEffect(() => {
    const topSellingCategoryUuid = uuid();

    if (initial) {
      setState({
        ...initial,
        menuList: {
          ...initial.menuList,
          wrapper: {
            ...initial.menuList?.wrapper,
            options: { ...initial.menuList?.wrapper?.options, topSellingCategoryUuid },
          },
        },
      });
    }
  }, [initial]);

  return <ComponentsConfigContext.Provider value={state}>{state && children}</ComponentsConfigContext.Provider>;
};

export const useComponentsConfig = () => {
  const context = useContext(ComponentsConfigContext);
  if (context === undefined) {
    throw new Error('useComponentsConfig must be used within a ComponentsConfigProvider');
  }
  return context;
};
